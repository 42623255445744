.App {
    width: 100%;
}

header {
    display: flex;
    flex-direction: row;
    width: 100%;
}

header .left {
    width: 50%;
}

header .right {
    display: flex;
    flex-direction: column;
    text-align: right;
    width: 50%;
    padding: 0.6em;
}

.row {
    display: flex;
    flex-direction: row;
}

.row .col {
    display: flex;
    flex-direction: column;
}

a {
    color: var(--blue);
    text-decoration: none;
}

h1 {
    font-family: EvangelionRegular;
    font-weight: 100;
    padding: 0;
    margin: 0.2em;
    font-size: 3.5em;
    color: var(--blue);
}
h2 {
    font-family: EvangelionItalic;
    font-weight: 100;
    padding: 0;
    margin: 0.2em;
    font-size: 2.5em;
    color: var(--blue);
}

.SearchBar {
    white-space: nowrap;
}

.Runner {
}

.Runner .isOwner {
    color: var(--yellow);
    font-family: SupplyMono;
}

.Runner .row {
    margin: 8px 0;
}

.Runner .right {
    margin: 0 8px;
}

.Runner .imgWrapper {
    border: 4px solid var(--yellow);
    padding: 14px;
}

.Runner img.runner {
    width: 400px;
    height: 400px;
    border-radius: 1px;
}

.AttrRow {
    font-size: 1.8em;
}

.AttrRow .name {
    color: var(--blue);
}

.AttrRow .value {
    color: var(--yellow);
}

.SanitizedNarrative {
    display: block;
    width: 100%;
    padding: 0.3em;
    margin: 0.2em;
    font-size: 1.5em;
    background-color: #2C2E3B;
    border-radius: 5px;
}

.NarrativeForm {
    width: 100%;
}

.NarrativeForm textarea {
    width: 100%;
    font-family: SupplyMono;
    font-size: 1.5em;
}

.imgWrapper {
    border: 4px solid var(--yellow);
    padding: 14px;
}

img.runner {
    width: 100%;
    border-radius: 1px;
}

.owner {
    padding: 5px;
    color: var(--black);
    background-color: var(--yellow);
    font-size: 1.2em;
    width: 100%;
}

div.narrative {
    display: block;
    padding: 0.05em 1em;
    margin: 0.2em;
    font-size: 1.5em;
    background-color: #2C2E3B;
    border-radius: 5px;
    width: 100%;
}

form {
    margin: 2em;
}

input {
    border: none;
    border-bottom: 1px solid var(--blue);
    background-color: var(--black);
    color: var(--yellow);
    outline: none;
    font-family: 'SupplyMono', monospace;
    font-size: 2.4em;
    width: 3em;
    text-align: center;
}

button {
    background-color: var(--black);
    border: 1px solid var(--blue);
    border-radius: 3px;
    font-family: 'SupplyMono', monospace;
    color: var(--yellow);
    font-size: 1.8em;
    padding: 0.25em 0.3em 0.05em 0.3em;
}
